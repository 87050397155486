<template>
  <b-sidebar
    :visible="isShowHashtagsAddNew"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="val => $emit('update:isShowHashtagsAddNew', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ hashtagId > 0 ? 'Edit' : 'Add' }} Hashtag</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <!-- Form -->
      <validation-observer ref="hashtagRules">
        <b-form class="p-2">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              vid="hashtagName"
              name="Hashtag"
              rules="required"
            >
              <b-form-input
                v-model="form.hashtagName"
                :state="errors.length > 0 ? false : null"
                placeholder="Hashtag Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-checkbox v-model="form.isActive" class="mb-2 mr-sm-2 mb-sm-0"
            >Active</b-form-checkbox
          >

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="addHashtag"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:isShowHashtagsAddNew',
  },
  props: {
    isShowHashtagsAddNew: {
      require: true,
    },
    hashtagId: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      form: {
        hashtagId: 0,
        hashtagName: '',
        isActive: true,
        createBy: '',
      },
      required,
    }
  },
  watch: {
    isShowHashtagsAddNew: {
      handler: 'getDataForEdit',
      deep: true,
    },
  },
  created() {},
  methods: {
    async addHashtag(event) {
      event.preventDefault()
      this.$refs.hashtagRules.validate().then(success => {
        if (success) {
          this.form.createBy = JSON.parse(
            localStorage.getItem('userData'),
          ).username
          const data = JSON.stringify(this.form)
          useJwt
            .addHashtag(data)
            .then(() => {
              this.$emit('update:isShowHashtagsAddNew', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success saving new hashtag: ${this.form.hashtagName}`,
                  icon: 'SaveIcon',
                  variant: 'success',
                },
              })
            })
            .then(() => {
              this.form.hashtagName = ''
              this.form.isActive = false
            })
            .then(() => {
              this.$refs.hashtagRules.reset()
            })
            .catch(error => {
              this.$refs.hashtagRules.setErrors(error.response.data.error)
            })
        }
      })
    },
    getDataForEdit() {
      this.$refs.hashtagRules.reset()
      if (this.isShowHashtagsAddNew && this.hashtagId > 0) {
        useJwt
          .getHashtagById(this.hashtagId)
          .then(response => {
            this.form.hashtagName = response.data.result.hashtagName
            this.form.isActive = response.data.result.isActive
            this.form.hashtagId = response.data.result.hashtagId
          })
          .catch(error => console.log(error))
      } else {
        this.form.hashtagId = 0
        this.form.hashtagName = ''
        this.form.isActive = true
      }
    },
  },
}
</script>

<style></style>
