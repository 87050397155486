<template>
  <div>
    <b-row align-h="between" class="mb-2">
      <b-col cols="4">
        <h2>Hashtags</h2>
      </b-col>
      <b-col md="12" xl="2"
        ><b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="btn btn-block btn-success"
          @click="clickAddButton"
          ><feather-icon icon="PlusCircleIcon" /> Add</b-button
        ></b-col
      >
    </b-row>
    <b-card>
      <div class="custom-search">
        <!-- advance search input -->
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Keyword:</label>
              <b-form-input
                placeholder="Keyword"
                type="text"
                class="d-inline-block"
                @input="advanceSearch"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Status:</label>
              <b-form-select
                id="status"
                v-model="selected"
                :options="options"
                @input="advanceSearch"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :line-numbers="true"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
          searchFn: customeSearchFn,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        theme="my-theme"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column : last update-->
          <span v-if="props.column.field === 'updateDate'">
            {{ props.row.updateDate | dateOrNull }}
            <br />
            <span class="font-small-3">
              Update by {{ props.row.updateBy }}
            </span>
          </span>

          <!-- Column : status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <div v-if="setStyleButtonAction()">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="warning"
                class="mr-1 btn-sm"
                @click="editHashtag(props.row.hashtagId)"
                ><feather-icon icon="EditIcon" class="mr-50" />Edit</b-button
              >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-danger"
                class="btn-sm"
                @click="
                  deleteHashtag(props.row.hashtagId, props.row.hashtagName)
                "
                ><feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />Delete</b-button
              >
            </div>
            <div v-else>
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <b-badge variant="light-secondary">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </b-badge>
                  </template>
                  <b-dropdown-item @click="editHashtag(props.row.hashtagId)">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="
                      deleteHashtag(props.row.hashtagId, props.row.hashtagName)
                    "
                  >
                    <feather-icon icon="Trash2Icon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </div>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  value => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <template #code>
        {{ codeAdvance }}
      </template>

      <hashtags-add-new
        :is-show-hashtags-add-new.sync="isShowHashtagsAddNew"
        :hashtag-id="hashtagId"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormSelect,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BPagination,
  BDropdown,
  BDropdownItem,
  BBadge,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import { codeAdvance } from './code'
import HashtagsAddNew from './HashtagsAddNew.vue'

export default {
  components: {
    HashtagsAddNew,
    BCard,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    VueGoodTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      codeAdvance,
      columns: [
        {
          label: 'Name',
          field: 'hashtagName',
          width: '50%',
        },
        {
          label: 'Status',
          field: 'status',
          sortable: true,
          width: '10%',
        },
        {
          label: 'Last Update',
          field: 'updateDate',
          sortable: true,
          width: '20%',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          width: '20%',
        },
      ],
      rows: [],
      searchTerm: '',
      selected: '',
      options: [
        { value: '', text: 'All status' },
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inactive' },
      ],
      isShowHashtagsAddNew: false,
      hashtagId: 0,
      window: {
        width: 0,
        height: 0,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active: 'light-success',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Inactive: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    isShowHashtagsAddNew: {
      handler: 'doUpdateRow', // call it in the context of your component object
      deep: true,
    },
  },
  created() {
    useJwt
      .getAllHashtags()
      .then(response => {
        this.rows = response.data
      })
      .catch(error => console.log(error))
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  methods: {
    advanceSearch(val) {
      this.searchTerm = val
    },
    editHashtag(hashtagId) {
      this.hashtagId = hashtagId
      this.isShowHashtagsAddNew = true
    },
    doUpdateRow() {
      useJwt
        .getAllHashtags()
        .then(response => {
          this.rows = response.data
        })
        .catch(error => console.log(error))
    },
    clickAddButton() {
      this.isShowHashtagsAddNew = true
      this.hashtagId = 0
    },
    deleteHashtag(hashtagId, hashtagName) {
      this.$swal({
        title: 'Are you sure?',
        text: `Are you sure you want to delete hashtag: ${hashtagName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt
            .deleteHashtag(hashtagId)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success delete hashtag: ${hashtagName}`,
                  icon: 'Trash2Icon',
                  variant: 'success',
                },
              })
              this.doUpdateRow()
            })
            .then(this.doUpdateRow())
            .catch(error => console.log(error))
        }
      })
    },

    customeSearchFn(row, col, cellValue, searchTerm) {
      const cellVal = cellValue.toString().toLowerCase()
      if (searchTerm === 'active') {
        return cellVal === 'active'
      }
      if (searchTerm === 'inactive') {
        return cellVal === 'inactive'
      }
      return cellVal.includes(searchTerm)
    },

    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      this.setStyleButtonAction()
    },
    setStyleButtonAction() {
      if (this.window.width >= 1442) {
        return true
      }
      return false
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
